<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>了解威有</el-breadcrumb-item>
                <el-breadcrumb-item>人才招聘</el-breadcrumb-item>
                <el-breadcrumb-item>福利待遇</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="page_content">
            <el-table :data="tableData" border row-key="id" @row-click="handleRowClick" ref="tab">
                <el-table-column prop="id" align="center" label="id"> </el-table-column>
                <el-table-column prop="title" align="center" label="名称"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div v-if="row.children">
                            <!-- <button @click="openAdd(row)">添加子分类</button> -->
                            <VyAddBtn
                                @click.native="openAdd(row)"
                                :btnType="row.level == 1 ? '二级' : row.level == 2 ? '三级' : row.level == 3 ? '四级' : ''"
                            />
                        </div>
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            :title="form.id ? '编辑' : '添加子分类'"
            :visible.sync="ds"
            width="60%"
            @closed="is_look = false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="80px">
                <el-form-item label="名称">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="ds = false">取 消</el-button>
                <el-button type="primary" @click="yes" :disabled="once">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { VyAddBtn },
    data() {
        return {
            tableData: [],
            form: {
                pid: "",
                id: "",
                title: "",
            },
            ds: false,
            once: false,
        };
    },

    async created() {
        this.tableData = await this.getData();
        // function
    },

    methods: {
        async getData() {
            let { data } = await axios.get("/api/recruit/welfareshow");
            function dg(arr, num = 0) {
                num++;
                arr.forEach(e => {
                    e.level = num;
                    if (e.children) {
                        dg(e.children, num);
                    }
                });
                return arr;
            }
            data = dg(data);
            return data;
        },
        openAdd(row) {
            this.form = {
                pid: row.id,
                id: "",
                title: "",
            };
            this.ds = true;
        },
        openSet(row) {
            this.form = {
                ...row,
            };
            this.ds = true;
        },
        // 确认
        async yes() {
            if (this.once) return;
            this.once = true;
            let { form } = this;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/recruit/welfareup", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/recruit/welfareadd", QueryString.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.ds = false;
            this.tableData = await this.getData();
            this.once = false;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/recruit/welfaredel", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
</style>
